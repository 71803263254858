<template>
  <div class="export-setting style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="8">
          <div class="title-header">
            Gestion les modèles exportation
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="loadingExport"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="2">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="error">
              <ul v-if="Array.isArray(error)" class="mb-0">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GMETHAME')"
                @click="openModalExportDynamic"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter un modèle d'export</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <div class="table-gestion-content">
      <v-data-table
        v-model="selectedTable"
        :headers="computedFields"
        :items="models"
        :items-per-page="perPage"
        class="table-gestion-model-export"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          loadingExport
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.name`]="{ item }">
          <td class="custom-cell">
            {{ item.name }}
          </td>
        </template>
        <template v-slot:[`item.user_name`]="{ item }">
          <td class="custom-cell">
            {{ item.user_name | CreateurFormat }}
          </td>
        </template>
        <template v-slot:[`item.statut`]="{ item }">
          <td class="custom-cell">
            {{ item.statut | StatutFormat }}
          </td>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <div class="d-flex actions-style-table">
              <div
                v-if="checkPermission('GMETHVME')"
                class="bloc-icon-gestion mr-2"
                @click.stop.prevent="handleClickShow(item)"
                title="Visualiser un modal export"
              >
                <font-awesome-icon icon="eye" />
              </div>
              <div
                v-if="
                  (getUserData.role == 'admin' ||
                    item.user_name == getUserData.name) &&
                    checkPermission('GMETHMDE')
                "
                class="bloc-icon-gestion mr-2"
                @click.stop.prevent="handleClickUpdate(item)"
                title="Modifier modèle d'export"
              >
                <font-awesome-icon icon="pencil-alt" />
              </div>
              <div
                v-if="
                  (getUserData.role == 'admin' ||
                    item.user_name == getUserData.name) &&
                    checkPermission('GMETHSME') &&
                    !item.name.includes('(API)')
                "
                class="bloc-icon-gestion mr-2"
                @click.stop.prevent="handleClickDelete(item)"
                title="Supprimer modèle d'export"
              >
                <font-awesome-icon icon="trash" />
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
      <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            :total-visible="7"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            v-model="perPage"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="addModelExportDynamique"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un modèle d'export</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addModelExportDynamique')"
            title="Fermer la modal"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addModelExportDynamique" lazy-validation>
            <v-row>
              <v-col cols="6">
                <span class="bold-text"> Nom Modèle</span>
              </v-col>
              <v-col cols="6">
                <span class="bold-text"> Partagable</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  required
                  dense
                  v-model="model"
                  class="msg-error"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom de produit est obligatoire']"
                  validate-on-blur
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col
                cols="1"
                class="config-check-export-dynamique margin-left-row"
              >
                <v-switch v-model="statut" color="#704ad1"></v-switch>
              </v-col>
            </v-row>
            <v-row class="margin-row-top">
              <v-col cols="1"></v-col>
              <v-col cols="4"
                ><span class="bold-text"> Description colonne</span></v-col
              >
              <v-col> <span class="bold-text"> Nom de colonne</span></v-col>
            </v-row>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col class="config-check-export-dynamique mb-2">
                <v-checkbox
                  label="Tous"
                  v-model="checkAll"
                  @change="checkAllColumn"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col></v-col>
            </v-row>
            <draggable :list="columns">
              <v-row
                v-for="(column, index) in columns"
                :key="index"
                class="row-margin"
              >
                <v-col cols="1">
                  <img src="@/assets/sort.png" class="sort-icon ml-2" />
                </v-col>
                <v-col class="config-check-export-dynamique flex-ligne">
                  <v-checkbox
                    :id="'checkbox-1' + index"
                    v-model="column.check"
                    @change="computedCheckColumn"
                    color="#704ad1"
                    :label="column.description"
                    :value="true"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  >
                  </v-checkbox>
                  <div id="info-wrap">
                    <div class="info-column ml-2 ">
                      <v-icon
                        class="pointer"
                        color="success"
                        v-if="
                          column.code == '$num_dossier' ||
                            column.code == '$client_dossier' ||
                            column.code == '$montant_final' ||
                            column.code == '$bureau_etude' ||
                            column.code == '$installeur' ||
                            column.code == '$date_paiement_dossier' ||
                            column.code == '$date_depot'
                        "
                        title="Cette colonne pour les factures support / Master"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="primary"
                        v-if="
                          column.code == '$qte' ||
                            column.code == '$pu_ht' ||
                            column.code == '$ht_produit' ||
                            column.code == '$nom_produit' ||
                            column.code == '$description_produit' ||
                            column.code == '$ref_produit'
                        "
                        title="Cette colonne peut faire la duplication des factures"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="yellow"
                        v-if="column.code == '$comptabilite'"
                        title="Cette colonne pour l'interface comptabilité"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="secondary"
                        v-if="column.code == '$sous_objet'"
                        title="Cette colonne pour les factures Sci / Mensuel"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="orange"
                        v-if="
                          column.code == '$organisme' ||
                            column.code == '$Kwh' ||
                            column.code == '$num_fac_avoir' ||
                            column.code == '$kwh_avoir' ||
                            column.code == '$ttc_avoir' ||
                            column.code == '$num_depot' ||
                            column.code == '$nom_depot' ||
                            column.code == '$ratio' ||
                            column.code == '$type' ||
                            column.code == '$master_filiale'
                        "
                        title="Cette colonne pour les factures obligées"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </div>
                  </div>
                </v-col>

                <v-col>
                  <v-text-field
                    :rules="[v => !!v || 'nom de colonne est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    v-model="column.nom_colonne"
                    required
                    :auto-focus="false"
                    outlined
                    :persistent-placeholder="true"
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </draggable>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul
                v-if="Array.isArray(error)"
                class="mb-0"
                style="list-style-type: none"
              >
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="submitModeleExport"
            :loading="loadingExport"
            :disabled="loadingExport"
            :class="{ loader: loadingExport }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addModelExportDynamique')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="DeleteModelExportDynamique"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un modèle d'export</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('DeleteModelExportDynamique')"
            title="Fermer la modal"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer ce modèle
              <span class="bold-text">
                {{ modelToDelete ? modelToDelete.name : '-' }}</span
              >
              ?
            </p>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loadingExport"
            :class="{ loader: loadingExport }"
            @click.prevent.stop="deleteModeleExport"
            >Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('DeleteModelExportDynamique')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalModelExport"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Visualiser modèle d'export</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('showModalModelExport')"
            title="Fermer la modal"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            ref="showModalModelExport"
            lazy-validation
            v-if="modelToShow"
            class="mt-2"
          >
            <v-row v-for="(column, index) in columnsChecked" :key="index">
              <v-col cols="" class="flex-ligne">
                <v-text-field
                  :label="column.description"
                  :persistent-placeholder="true"
                  dense
                  outlined
                  v-model="column.column_value"
                  :auto-focus="false"
                  disabled
                  color="#704ad1"
                >
                </v-text-field>
                <div id="info-wrap">
                  <div class="info-column ml-2 ">
                    <v-icon
                      class="pointer"
                      color="success"
                      v-if="
                        column.column == '$num_dossier' ||
                          column.column == '$client_dossier' ||
                          column.column == '$montant_final' ||
                          column.column == '$bureau_etude' ||
                          column.column == '$installeur' ||
                          column.column == '$date_paiement_dossier' ||
                          column.column == '$date_depot'
                      "
                      title="Cette colonne pour les factures support / Master"
                      >mdi-alert-circle-outline</v-icon
                    >
                    <v-icon
                      class="pointer"
                      color="primary"
                      v-if="
                        column.column == '$qte' ||
                          column.column == '$pu_ht' ||
                          column.column == '$ht_produit' ||
                          column.column == '$nom_produit' ||
                          column.column == '$description_produit' ||
                          column.column == '$ref_produit'
                      "
                      title="Cette colonne peut faire la duplication des factures"
                      >mdi-alert-circle-outline</v-icon
                    >
                    <v-icon
                      class="pointer"
                      color="yellow"
                      v-if="column.column == '$comptabilite'"
                      title="Cette colonne pour l'interface comptabilité"
                      >mdi-alert-circle-outline</v-icon
                    >
                    <v-icon
                      class="pointer"
                      color="secondary"
                      v-if="column.column == '$sous_objet'"
                      title="Cette colonne pour les factures Sci / Mensuel"
                      >mdi-alert-circle-outline</v-icon
                    >
                    <v-icon
                      class="pointer"
                      color="orange"
                      v-if="
                        column.column == '$organisme' ||
                          column.column == '$Kwh' ||
                          column.column == '$num_fac_avoir' ||
                          column.column == '$kwh_avoir' ||
                          column.column == '$ttc_avoir' ||
                          column.column == '$num_depot' ||
                          column.column == '$nom_depot' ||
                          column.column == '$ratio' ||
                          column.column == '$type' ||
                          column.column == '$master_filiale'
                      "
                      title="Cette colonne pour les factures obligées"
                      >mdi-alert-circle-outline</v-icon
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn outlined @click.prevent="hideModal('showModalModelExport')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="updateModelExportDynamique"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier modèle d'export</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('updateModelExportDynamique')"
            title="Fermer la modal"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            ref="updateModelExportDynamique"
            lazy-validation
            v-if="modelToUpdate"
          >
            <v-row>
              <v-col cols="4">
                <span class="bold-text"> Nom Modèle</span>
              </v-col>
              <v-col cols="5">
                <span class="bold-text"> Partagable</span>
              </v-col>
              <v-col cols="3">
                <span class="bold-text"> Actualiser</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-text-field
                  outlined
                  required
                  dense
                  v-model="modelToUpdate.name"
                  class="msg-error"
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Nom de produit est obligatoire']"
                  validate-on-blur
                  :disabled="
                    modelToUpdate &&
                      modelToUpdate.name &&
                      modelToUpdate.name.includes('(API)')
                  "
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                class="config-check-export-dynamique margin-left-row-update"
              >
                <v-switch
                  v-model="modelToUpdate.statut"
                  color="#704ad1"
                ></v-switch>
              </v-col>
              <v-col cols="1">
                <div
                  class="block-icon-reglement-fac"
                  @click="handleRefreshModalExport()"
                  title="Actualiser la liste de colonne"
                >
                  <font-awesome-icon icon="sync-alt" class="mt-0 pb-1" />
                </div>
              </v-col>
            </v-row>
            <v-row class="margin-row-top">
              <v-col cols="1"></v-col>
              <v-col cols="4"
                ><span class="bold-text"> Description colonne</span></v-col
              >
              <v-col> <span class="bold-text"> Nom de colonne</span></v-col>
            </v-row>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col class="config-check-export-dynamique mb-2">
                <v-checkbox
                  label="Tous"
                  v-model="checkAll"
                  @change="checkAllColumnUpdate"
                  color="#704ad1"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col></v-col>
            </v-row>
            <draggable :list="modelToUpdate.columns">
              <v-row
                v-for="(column, index) in modelToUpdate.columns"
                :key="index"
                class="row-margin"
              >
                <v-col cols="1">
                  <img src="@/assets/sort.png" class="sort-icon ml-2" />
                </v-col>
                <v-col class="config-check-export-dynamique flex-ligne">
                  <v-checkbox
                    :id="'checkbox-1' + index"
                    v-model="column.checked"
                    @change="computedModelUpdateCheck"
                    color="#704ad1"
                    :label="column.description"
                    :value="true"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  >
                  </v-checkbox>
                  <div id="info-wrap">
                    <div class="info-column ml-2 ">
                      <v-icon
                        class="pointer"
                        color="success"
                        v-if="
                          column.column == '$num_dossier' ||
                            column.column == '$client_dossier' ||
                            column.column == '$montant_final' ||
                            column.column == '$bureau_etude' ||
                            column.column == '$installeur' ||
                            column.column == '$date_paiement_dossier' ||
                            column.column == '$date_depot'
                        "
                        title="Cette colonne pour les factures support / Master"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="primary"
                        v-if="
                          column.column == '$qte' ||
                            column.column == '$pu_ht' ||
                            column.column == '$ht_produit' ||
                            column.column == '$nom_produit' ||
                            column.column == '$description_produit' ||
                            column.column == '$ref_produit'
                        "
                        title="Cette colonne peut faire la duplication des factures"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="yellow"
                        v-if="column.column == '$comptabilite'"
                        title="Cette colonne pour l'interface comptabilité"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="secondary"
                        v-if="column.column == '$sous_objet'"
                        title="Cette colonne pour les factures Sci / Mensuel"
                        >mdi-alert-circle-outline</v-icon
                      >
                      <v-icon
                        class="pointer"
                        color="orange"
                        v-if="
                          column.column == '$organisme' ||
                            column.column == '$Kwh' ||
                            column.column == '$num_fac_avoir' ||
                            column.column == '$kwh_avoir' ||
                            column.column == '$ttc_avoir' ||
                            column.column == '$num_depot' ||
                            column.column == '$nom_depot' ||
                            column.column == '$ratio' ||
                            column.column == '$type' ||
                            column.column == '$master_filiale'
                        "
                        title="Cette colonne pour les factures obligées"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </div>
                  </div>
                </v-col>

                <v-col>
                  <v-text-field
                    :rules="[v => !!v || 'nom de colonne est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    v-model="column.column_value"
                    required
                    :auto-focus="false"
                    outlined
                    :disabled="
                      column.column == '$num_facture' && modelToUpdate.is_system
                    "
                    :persistent-placeholder="true"
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </draggable>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul
                v-if="Array.isArray(error)"
                class="mb-0"
                style="list-style-type: none"
              >
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleSubmitUpdate"
            :loading="loadingExport"
            :disabled="loadingExport"
            :class="{ loader: loadingExport }"
          >
            Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('updateModelExportDynamique')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      checkAll: false,
      statut: false,
      model: null,
      modelToUpdate: null,
      oldModelToUpdate: null,
      oldModalToUpdate: null,
      modelToDelete: null,
      modelToShow: null,
      columnsChecked: [],
      error: null,
      page: 1,
      perPage: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      columns: [
        {
          code: '$societe',
          description: 'Sociéte vendeur',
          nom_colonne: 'Societe',
          check: false
        },
        {
          code: '$reste',
          description: 'Reste aprés avoir et paiment',
          nom_colonne: 'Reste',
          check: false
        },
        {
          code: '$type_facture',
          description: 'Type facture',
          nom_colonne: 'Type Facture',
          check: false
        },
        {
          code: '$num_facture',
          description: 'Numéro facture',
          nom_colonne: 'Numéro Facture',
          check: false
        },
        {
          code: '$client',
          description: 'Sociéte client',
          nom_colonne: 'Client',
          check: false
        },
        {
          code: '$net_ht',
          description: 'net ht',
          nom_colonne: 'NET HT',
          check: false
        },
        { code: '$tva', description: 'tva', nom_colonne: 'TVA', check: false },
        { code: '$ttc', description: 'ttc', nom_colonne: 'TTC', check: false },
        {
          code: '$statut',
          description: 'statut',
          nom_colonne: 'Statut',
          check: false
        },
        {
          code: '$iban',
          description: 'iban',
          nom_colonne: 'IBAN',
          check: false
        },
        { code: '$bic', description: 'bic', nom_colonne: 'BIC', check: false },
        {
          code: '$banque',
          description: 'bank',
          nom_colonne: 'Banque',
          check: false
        },
        {
          code: '$date_paiement',
          description: 'Date paiement',
          nom_colonne: 'Date paiement',
          check: false
        },
        {
          code: '$mode_reglement',
          description: 'Mode reglement',
          nom_colonne: 'Mode reglement',
          check: false
        },
        {
          code: '$date_creation',
          description: 'Date creation Facture',
          nom_colonne: 'Date creation Facture',
          check: false
        },
        {
          code: '$categorie',
          description: 'Categorie',
          nom_colonne: 'Catégorie',
          check: false
        },
        {
          code: '$objet',
          description: 'Objet',
          nom_colonne: 'Objet',
          check: false
        },
        {
          code: '$famille',
          description: 'Famille',
          nom_colonne: 'Famille',
          check: false
        },
        {
          code: '$num_dossier',
          description: 'Numero dossier',
          nom_colonne: 'Numero de dossier',
          check: false
        },
        {
          code: '$date_paiement_dossier',
          description: 'Date paiement dossier',
          nom_colonne: 'Date paiement dossier',
          check: false
        },
        {
          code: '$date_depot',
          description: 'Date dépôt dossier',
          nom_colonne: 'Date dépôt dossier',
          check: false
        },
        {
          code: '$client_dossier',
          description: 'Client dossier',
          nom_colonne: 'Client de dossier',
          check: false
        },
        {
          code: '$montant_final',
          description: 'Montant final',
          nom_colonne: 'Montant final',
          check: false
        },
        {
          code: '$installeur',
          description: 'Installeur',
          nom_colonne: 'Installeur',
          check: false
        },
        {
          code: '$bureau_etude',
          description: "Bureau d'étude",
          nom_colonne: "Bureau d'étude",
          check: false
        },
        {
          code: '$nom_produit',
          description: 'Nom produit',
          nom_colonne: 'Nom produit',
          check: false
        },
        {
          code: '$description_produit',
          description: 'Description produit',
          nom_colonne: 'Description produit',
          check: false
        },
        {
          code: '$ref_produit',
          description: 'Référence produit',
          nom_colonne: 'Référence produit',
          check: false
        },
        {
          code: '$qte',
          description: 'Quantité produit',
          nom_colonne: 'Quantité produit',
          check: false
        },
        {
          code: '$pu_ht',
          description: 'PU HT produit',
          nom_colonne: 'PU HT produit',
          check: false
        },
        {
          code: '$ht_produit',
          description: 'MONTANT HT produit',
          nom_colonne: 'MONTANT HT produit',
          check: false
        },
        {
          code: '$user',
          description: 'Crée par',
          nom_colonne: 'Crée par',
          check: false
        },
        {
          code: '$comptabilite',
          description: 'Comptabilisé',
          nom_colonne: 'Comptabilisé',
          check: false
        },
        {
          code: '$sous_objet',
          description: 'Mois',
          nom_colonne: 'Mois',
          check: false
        },
        {
          code: '$created_at',
          description: 'Date création',
          nom_colonne: 'Date création',
          check: false
        },
        {
          code: '$organisme',
          description: 'Organisme',
          nom_colonne: 'Organisme',
          check: false
        },
        {
          code: '$Kwh',
          description: 'Kwh cumac',
          nom_colonne: 'Kwh cumac',
          check: false
        },
        {
          code: '$num_fac_avoir',
          description: "Numéro de la facture d'avoir",
          nom_colonne: "Numéro de la facture d'avoir",
          check: false
        },
        {
          code: '$kwh_avoir',
          description: "Kwh cumac d'avoir",
          nom_colonne: "Kwh cumac d'avoir",
          check: false
        },
        {
          code: '$ttc_avoir',
          description: 'Montant TTC avoir',
          nom_colonne: 'Montant TTC avoir',
          check: false
        },
        {
          code: '$num_depot',
          description: 'Dépôt N°',
          nom_colonne: 'Dépôt N°',
          check: false
        },
        {
          code: '$nom_depot',
          description: 'Nom du dépôt',
          nom_colonne: 'Nom du dépôt',
          check: false
        },
        {
          code: '$ratio',
          description: 'Ratio',
          nom_colonne: 'Ratio',
          check: false
        },
        {
          code: '$type',
          description: 'Type',
          nom_colonne: 'Type',
          check: false
        },
        {
          code: '$master_filiale',
          description: 'Master Filiale',
          nom_colonne: 'Master Filiale',
          check: false
        },
        {
          code: '$mail_lot',
          description: 'Numéro de lot mail',
          nom_colonne: 'Numéro de lot mail',
          check: false
        },
        {
          code: '$description',
          description: 'Description',
          nom_colonne: 'Description',
          check: false
        },
        {
          code: '$reste_a_payer',
          description: 'Reste à payer',
          nom_colonne: 'Reste à payer',
          check: false
        }
      ],
      selectedTable: [],
      addModelExportDynamique: false,
      DeleteModelExportDynamique: false,
      updateModelExportDynamique: false,
      showModalModelExport: false
    };
  },
  computed: {
    ...mapGetters([
      'models',
      'loadingExport',
      'modelsTotalRows',
      'getUserData',
      'checkPermission'
    ]),
    totalPages() {
      if (this.modelsTotalRows) {
        return Math.ceil(this.modelsTotalRows / this.perPage);
      }
      return this.modelsTotalRows;
    },
    computedFields() {
      let fields = [
        { value: 'name', text: 'Nom', show: this.checkPermission('GMETHACN') },
        {
          value: 'user_name',
          text: 'Créateur',
          show: this.checkPermission('GMETHACR')
        },
        {
          value: 'statut',
          text: 'Statut',
          show: this.checkPermission('GMETHACS')
        },
        {
          value: 'Actions',
          text: 'Actions',
          show: true
        }
      ];
      return fields.filter(field => field.show == true);
    }
  },
  methods: {
    ...mapActions([
      'getAllModelExports',
      'AddNewModelExport',
      'updateModelExport',
      'deleteModelExport'
    ]),
    openModalExportDynamic() {
      this.addModelExportDynamique = true;
    },
    handleRefreshModalExport() {
      this.columns.forEach(item => {
        let find = this.modelToUpdate.columns.find(i => i.code == item.code);

        if (!find) {
          this.modelToUpdate.columns.push({
            ...item,
            column_value: item.nom_colonne,
            column: item.code
          });
        }
      });
    },
    computedModelUpdateCheck(value) {
      if (value == false && this.checkAll) {
        this.checkAll = false;
      } else {
        let selected = [];
        if (
          this.modelToUpdate &&
          this.modelToUpdate.columns &&
          this.modelToUpdate.columns.length
        ) {
          this.modelToUpdate.columns.forEach(item => {
            if (item.checked == true) {
              selected.push(item);
            }
          });
        }
        if (this.columns && this.columns.length == selected.length)
          [(this.checkAll = true)];
      }
    },
    checkAllColumnUpdate() {
      if (this.checkAll == true) {
        if (
          this.modelToUpdate &&
          this.modelToUpdate.columns &&
          this.modelToUpdate.columns.length
        ) {
          this.modelToUpdate.columns.forEach(item => {
            item.checked = true;
          });
        }
      } else {
        if (
          this.modelToUpdate &&
          this.modelToUpdate.columns &&
          this.modelToUpdate.columns.length
        ) {
          this.modelToUpdate.columns.forEach(item => {
            if (
              this.oldModalToUpdate &&
              this.oldModalToUpdate.columns &&
              this.oldModalToUpdate.columns.length
            ) {
              this.oldModalToUpdate.columns.forEach(column => {
                if (column.id == item.id) {
                  item.checked = column.checked;
                }
              });
            }
          });
        }
      }
    },
    computedCheckColumn(value) {
      if (value == false && this.checkAll) {
        this.checkAll = false;
      } else {
        let selected = [];
        if (this.columns && this.columns.length) {
          this.columns.forEach(item => {
            if (item.check == true) {
              selected.push(item);
            }
          });
        }
        if (this.columns && this.columns.length == selected.length)
          [(this.checkAll = true)];
      }
    },
    checkAllColumn() {
      if (this.checkAll == true) {
        if (this.columns && this.columns.length) {
          this.columns.forEach(item => {
            item.check = true;
          });
        }
      } else {
        if (this.columns && this.columns.length) {
          this.columns.forEach(item => {
            item.check = false;
          });
        }
      }
    },
    handleClickShow(item) {
      this.modelToShow = item;

      this.modelToShow?.columns?.forEach(element => {
        if (element.checked == true) {
          this.columnsChecked.push(element);
        }
      });
      this.showModalModelExport = true;
    },
    hideModal(ref) {
      this.resetModal();
      this[ref] = false;
      if (ref == 'addModelExportDynamique') {
        this.$refs.addModelExportDynamique.reset();
      }
      if (ref == 'updateModelExportDynamique') {
        this.$refs.updateModelExportDynamique.reset();
      }
    },
    resetModal() {
      this.columns = [
        {
          code: '$societe',
          description: 'Sociéte vendeur',
          nom_colonne: 'Societe',
          check: false
        },
        {
          code: '$reste',
          description: 'Reste aprés avoir et paiment',
          nom_colonne: 'Reste',
          check: false
        },
        {
          code: '$type_facture',
          description: 'Type facture',
          nom_colonne: 'Type Facture',
          check: false
        },
        {
          code: '$num_facture',
          description: 'Numéro facture',
          nom_colonne: 'Numéro Facture',
          check: false
        },
        {
          code: '$client',
          description: 'Sociéte client',
          nom_colonne: 'Client',
          check: false
        },
        {
          code: '$net_ht',
          description: 'net ht',
          nom_colonne: 'NET HT',
          check: false
        },
        { code: '$tva', description: 'tva', nom_colonne: 'TVA', check: false },
        { code: '$ttc', description: 'ttc', nom_colonne: 'TTC', check: false },
        {
          code: '$statut',
          description: 'statut',
          nom_colonne: 'Statut',
          check: false
        },
        {
          code: '$iban',
          description: 'iban',
          nom_colonne: 'IBAN',
          check: false
        },
        { code: '$bic', description: 'bic', nom_colonne: 'BIC', check: false },
        {
          code: '$banque',
          description: 'bank',
          nom_colonne: 'Banque',
          check: false
        },
        {
          code: '$date_paiement',
          description: 'Date paiement',
          nom_colonne: 'Date paiement',
          check: false
        },
        {
          code: '$mode_reglement',
          description: 'Mode reglement',
          nom_colonne: 'Mode reglement',
          check: false
        },
        {
          code: '$date_creation',
          description: 'Date creation Facture',
          nom_colonne: 'Date creation Facture',
          check: false
        },
        {
          code: '$categorie',
          description: 'Categorie',
          nom_colonne: 'Catégorie',
          check: false
        },
        {
          code: '$objet',
          description: 'Objet',
          nom_colonne: 'Objet',
          check: false
        },
        {
          code: '$famille',
          description: 'Famille',
          nom_colonne: 'Famille',
          check: false
        },
        {
          code: '$num_dossier',
          description: 'Numero dossier',
          nom_colonne: 'Numero de dossier',
          check: false
        },
        {
          code: '$date_paiement_dossier',
          description: 'Date paiement dossier',
          nom_colonne: 'Date paiement dossier',
          check: false
        },
        {
          code: '$date_depot',
          description: 'Date dépôt dossier',
          nom_colonne: 'Date dépôt dossier',
          check: false
        },
        {
          code: '$client_dossier',
          description: 'Client dossier',
          nom_colonne: 'Client de dossier',
          check: false
        },
        {
          code: '$montant_final',
          description: 'Montant final',
          nom_colonne: 'Montant final',
          check: false
        },
        {
          code: '$installeur',
          description: 'Installeur',
          nom_colonne: 'Installeur',
          check: false
        },
        {
          code: '$bureau_etude',
          description: "Bureau d'étude",
          nom_colonne: "Bureau d'étude",
          check: false
        },
        {
          code: '$nom_produit',
          description: 'Nom produit',
          nom_colonne: 'Nom produit',
          check: false
        },
        {
          code: '$description_produit',
          description: 'Description produit',
          nom_colonne: 'Description produit',
          check: false
        },
        {
          code: '$ref_produit',
          description: 'Référence produit',
          nom_colonne: 'Référence produit',
          check: false
        },
        {
          code: '$qte',
          description: 'Quantité produit',
          nom_colonne: 'Quantité produit',
          check: false
        },
        {
          code: '$pu_ht',
          description: 'PU HT produit',
          nom_colonne: 'PU HT produit',
          check: false
        },
        {
          code: '$ht_produit',
          description: 'MONTANT HT produit',
          nom_colonne: 'MONTANT HT produit',
          check: false
        },
        {
          code: '$user',
          description: 'Crée par',
          nom_colonne: 'Crée par',
          check: false
        },
        {
          code: '$comptabilite',
          description: 'Comptabilisé',
          nom_colonne: 'Comptabilisé',
          check: false
        },
        {
          code: '$sous_objet',
          description: 'Mois',
          nom_colonne: 'Mois',
          check: false
        },
        {
          code: '$created_at',
          description: 'Date création',
          nom_colonne: 'Date création',
          check: false
        },
        {
          code: '$organisme',
          description: 'Organisme',
          nom_colonne: 'Organisme',
          check: false
        },
        {
          code: '$Kwh',
          description: 'Kwh cumac',
          nom_colonne: 'Kwh cumac',
          check: false
        },
        {
          code: '$num_fac_avoir',
          description: "Numéro de la facture d'avoir",
          nom_colonne: "Numéro de la facture d'avoir",
          check: false
        },
        {
          code: '$kwh_avoir',
          description: "Kwh cumac d'avoir",
          nom_colonne: "Kwh cumac d'avoir",
          check: false
        },
        {
          code: '$ttc_avoir',
          description: 'Montant TTC avoir',
          nom_colonne: 'Montant TTC avoir',
          check: false
        },
        {
          code: '$num_depot',
          description: 'Dépôt N°',
          nom_colonne: 'Dépôt N°',
          check: false
        },
        {
          code: '$nom_depot',
          description: 'Nom du dépôt',
          nom_colonne: 'Nom du dépôt',
          check: false
        },
        {
          code: '$ratio',
          description: 'Ratio',
          nom_colonne: 'Ratio',
          check: false
        },
        {
          code: '$type',
          description: 'Type',
          nom_colonne: 'Type',
          check: false
        },
        {
          code: '$master_filiale',
          description: 'Master Filiale',
          nom_colonne: 'Master Filiale',
          check: false
        },
        {
          code: '$mail_lot',
          description: 'Numéro de lot',
          nom_colonne: 'Numéro de lot',
          check: false
        },
        {
          code: '$description',
          description: 'Description',
          nom_colonne: 'Description',
          check: false
        },
        {
          code: '$reste_a_payer',
          description: 'Reste à payer',
          nom_colonne: 'Reste à payer',
          check: false
        }
      ];
      this.model = '';
      this.modelToUpdate = {
        statut: false
      };
      this.oldModelToUpdate = null;
      this.oldModalToUpdate = null;

      this.modelToDelete = null;
      this.modelToShow = null;
      this.columnsChecked = [];
      this.page = 1;
      this.perPage = 10;
      this.checkAll = false;
    },
    async submitModeleExport() {
      if (this.$refs.addModelExportDynamique.validate()) {
        this.error = null;
        let selected = [];
        this.columns.map(column => {
          if (column.check == true) {
            selected.push(column);
          }
        });
        if (selected.length == 0) {
          this.error = 'Séléctionner au moins un champ';
        } else {
          let bodyFormData = new FormData();
          bodyFormData.append('name', this.model);
          if (this.statut == true) {
            this.statut = 1;
          } else {
            this.statut = 0;
          }
          bodyFormData.append('statut', this.statut);

          for (let i = 0; i < this.columns.length; i++) {
            bodyFormData.append(
              'columns[' + i + '][column]',
              this.columns[i].code
            );
            bodyFormData.append(
              'columns[' + i + '][description]',
              this.columns[i].description
            );
            bodyFormData.append(
              'columns[' + i + '][column_value]',
              this.columns[i].nom_colonne
            );
            bodyFormData.append('columns[' + i + '][index]', i);
            if (this.columns[i] && this.columns[i].check) {
              bodyFormData.append('columns[' + i + '][blocked]', 0);
            } else {
              bodyFormData.append('columns[' + i + '][blocked]', 1);
            }
          }
          const response = await this.AddNewModelExport(bodyFormData);
          if (response) {
            this.hideModal('addModelExportDynamique');
            this.resetModal();
          } else {
            this.error = 'Une error est survenue';
          }
        }
      }
    },
    handleClickUpdate(item) {
      // this.modelToUpdate = { ...item };
      this.modelToUpdate = JSON.parse(JSON.stringify(item));
      this.oldModelToUpdate = item;
      let selected = [];
      this.modelToUpdate.columns.forEach(item => {
        if (item.checked == true) {
          selected.push(item);
        }
      });
      if (this.modelToUpdate.columns.length == selected.length)
        [(this.checkAll = true)];
      this.updateModelExportDynamique = true;
    },
    async handleSubmitUpdate() {
      this.error = null;
      let selected = [];
      if (this.modelToUpdate.columns && this.modelToUpdate.columns.length) {
        this.modelToUpdate.columns.map(column => {
          if (column.checked == true) {
            selected.push(column);
          }
        });
      }
      if (selected.length == 0) {
        this.error = 'Séléctionner au moins un champ';
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('id', this.modelToUpdate.id);
        bodyFormData.append('name', this.modelToUpdate.name);
        if (this.modelToUpdate.statut == true) {
          this.modelToUpdate.statut = 1;
        } else {
          this.modelToUpdate.statut = 0;
        }
        bodyFormData.append('statut', this.modelToUpdate.statut);

        for (let i = 0; i < this.modelToUpdate.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][id]',
            this.modelToUpdate.columns[i].id
          );

          bodyFormData.append(
            'columns[' + i + '][column]',
            this.modelToUpdate.columns[i].column
          );
          bodyFormData.append(
            'columns[' + i + '][description]',
            this.modelToUpdate.columns[i].description
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.modelToUpdate.columns[i].column_value
          );
          bodyFormData.append('columns[' + i + '][index]', i);
          if (
            this.modelToUpdate.columns[i] &&
            this.modelToUpdate.columns[i].checked
          ) {
            bodyFormData.append('columns[' + i + '][blocked]', 0);
          } else {
            bodyFormData.append('columns[' + i + '][blocked]', 1);
          }
        }
        let payload = {
          oldModelToUpdate: this.oldModelToUpdate,
          newModel: bodyFormData
        };
        const response = await this.updateModelExport(payload);
        if (response) {
          this.hideModal('updateModelExportDynamique');
          this.resetModal();
        } else {
          this.error = 'Une erreur est survenue';
        }
      }
    },
    handleClickDelete(item) {
      this.modelToDelete = item;
      this.DeleteModelExportDynamique = true;
    },
    async deleteModeleExport() {
      const response = await this.deleteModelExport(this.modelToDelete.id);
      if (response) {
        this.hideModal('DeleteModelExportDynamique');
      } else {
        this.error = 'Une erreur est survenue';
      }
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getAllModelExports({
        per_page: this.perPage,
        page: this.page
      });
    },
    async changePerPage() {
      this.page = 1;
      await this.getAllModelExports({
        per_page: this.perPage,
        page: this.page
      });
    }
  },
  components: {
    draggable
  },
  filters: {
    CreateurFormat: value => {
      if (value == 'admin') {
        return 'Système';
      } else {
        return value;
      }
    },
    StatutFormat: value => {
      if (value == true) {
        return 'Partagé';
      } else {
        return 'Non partagé';
      }
    }
  },
  mounted() {
    this.getAllModelExports({ per_page: this.perPage, page: this.page });
  }
};
</script>
<style lang="scss" scoped>
.export-setting {
  .export-body {
    // display: flex;
    margin: 5px 15px;
    height: calc(100vh - 197px) !important;
    .table-modele-export-height {
      width: 100%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 226px) !important;
        height: calc(100vh - 226px) !important;
        .button-danger-style,
        .btn-secondary {
          border-radius: 6px;
          padding: 3px 5px;
          font-size: 12px;
          width: 25px;
          height: 25px;
          margin: 2px;
        }
      }
    }
  }
}
.table-gestion-content {
  max-height: calc(100vh - 253px) !important;
  height: calc(100vh - 253px) !important;
}
.flex-ligne {
  display: flex;
  // align-items: center;
}
.sort-icon {
  height: 20px;
  width: 20px;
  cursor: move;
}
.refresh-icon-export-modal {
  height: 16px;
  width: 27px;
  background: #4d4bac;
  border-radius: 15px;
  cursor: pointer;
}
.row-margin {
  margin-bottom: -38px;
}
.margin-left-row {
  margin-left: 40px !important;
}
.margin-left-row-update {
  margin-left: 72px !important;
}
.margin-row-top {
  margin-top: 0px !important;
}
</style>
<style lang="scss">
.config-check-export-dynamique {
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
.icon-export-oblige {
  color: rgb(227, 106, 20);
}
.table-modele-export-height {
  background-color: #ffffff;
  border-radius: 25px;
  width: calc(100% - 205px);
  position: relative;
  padding: 7px;
  display: inline-grid;
}
.table-modele-export-height {
  max-height: calc(100vh - 218px) !important;
  height: calc(100vh - 218px) !important;
}
.export-dynamic {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .table-modele-export {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0px;
    .button-danger-style {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 15px;
      border: none;
    }
    .button-warning-style {
      background-color: #dc3c3c;
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 11px;
      border: none;
    }
  }
  .chargement-message-text {
    text-align: left;
  }
}
.table-modele-export {
  .table {
    display: table;
  }
  td,
  th {
    padding: 5px !important;
  }
}

.button-warning-style {
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 25px;
  padding: 6px 15px;
  border: none;
}

#info-wrap {
  overflow: hidden;
}

.description-column {
  width: 90%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
.visibilty-export {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
